import {
    getBaseHeaders,
    getExcelBaseHeaders,
    getFormDataHeaders,
    getDefaultHeaders,
    getTxtBaseHeaders,
    getZipBaseHeaders,
    getSimpleZipBaseHeaders, getAcceptCSVHeaders, getAuthHeader,
} from "./headers";
import { accounting_urls } from "./endpoints";

/**
  PAYMENTS API V1
*/

// REPORTS GROUP

export const getReservationsReport = (token, { _from, _to }) =>
  fetch(accounting_urls.common.reports.reservations(_from, _to), {
    method: "GET",
    headers: getBaseHeaders(token),
  })
    .then((r) => r.json())
    .then((r) => ({
      movements: r.reservations.map((reservation) => ({
        ...reservation,
        paymentInfo: reservation.payment,
        movement_id: reservation.reservation?.id,
        property_id: reservation.property?.id,
        description: `${reservation.type}: ${reservation.payment?.type}`,
        payment: reservation.payment?.amount,
        date: reservation.payment?.date,
        method: reservation.payment?.servipag_uid,
      })),
    }));

export const getContractsReport = (token, { _from, _to }) =>
  fetch(accounting_urls.common.reports.contracts(_from, _to), {
    method: "GET",
    headers: getBaseHeaders(token),
  })
    .then((r) => r.json())
    .then((r) => ({
      movements: r.contracts.map((contract) => ({
        ...contract,
        paymentInfo: contract.payment,
        movement_id: contract.contract_payment?.id,
        subscription_id: contract.quotation?.id,
        property_id: contract.property?.id,
        description: `Propietario: ${contract.owner?.name} ${contract.owner?.last_name}`,
        payment: contract.payment?.amount,
        date: contract.payment?.date,
        method: contract.payment?.servipag_uid,
      })),
      summary: r.summary,
    }));

export const getLeasesReport = (token, { _from, _to }) =>
  fetch(accounting_urls.common.reports.leases(_from, _to), {
    method: "GET",
    headers: getBaseHeaders(token),
  })
    .then((r) => r.json())
    .then((r) => ({
      movements: r.leases.map((lease) => ({
        ...lease,
        paymentInfo: lease.payment,
        movement_id: lease.payment_order?.id,
        subscription_id: lease.quotation?.id,
        property_id: lease.property?.id,
        description: `Propietario: ${lease.owner?.name} ${lease.owner?.last_name}`,
        payment: lease.payment?.breakdown?.owner?.total_payment,
        date: lease.payment?.date,
        method: lease.payment?.servipag_uid,
      })),
      summary: r.summary,
    }));

// USERS GROUP
export const getUser = (token, id) =>
  fetch(accounting_urls.users.user(id), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

// DOWNLOADS

//----------
// Chile
//----------

export const getExcelDirectPayments = (token, { _from, _to }) =>
  fetch(accounting_urls.common.payroll.directPayments(_from, _to), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getExcelOnlyBrokerage = (token, { _from, _to }) =>
  fetch(accounting_urls.common.payroll.onlyBrokerage(_from, _to), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getExcelGuaranteedPayments = (token, { _from, _to }) =>
  fetch(accounting_urls.cl.payroll.guaranteedPayments(_from, _to), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getExcelWarrantyPayments = (token, { _from, _to }) =>
  fetch(accounting_urls.cl.payroll.warrantyPayments(_from, _to), {

    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getExcelNormalPayments = (token, { _from, _to }) =>
  fetch(accounting_urls.cl.payroll.basicPayments(_from, _to), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getExcelRefundPayments = (token, { _from, _to }) =>
  fetch(accounting_urls.cl.payroll.refundPayments(_from, _to), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const postExcelPayments = (token, formData) =>
    fetch(accounting_urls.cl.payroll.uploadPayments(), {
        method:"POST",
        headers: getFormDataHeaders(token),
        body: formData
    });

export const postWarrantyUploadPayroll = (token, formData) =>
    fetch(accounting_urls.cl.payroll.warrantyUploadPayroll(), {
        method:"POST",
        headers: getFormDataHeaders(token),
        body: formData
    })


//----------
// Colombia
//----------
export const getBankAccountsRegister = (token, { _from, _to }) =>
  fetch(accounting_urls.co.downloads.bankAccounts.register(_from, _to), {
    method: "GET",
    headers: getTxtBaseHeaders(token),
  }).then((r) => r.blob());

export const getOutflowsPayroll = (token, { _from, _to }) =>
  fetch(accounting_urls.co.downloads.movements.payroll(_from, _to), {
    method: "GET",
    headers: getTxtBaseHeaders(token),
  }).then((r) => r.blob());

export const getOutflowsPayrollJPM = (token, {_from, _to}) =>
    fetch(accounting_urls.co.payroll.download.jpm(_from, _to), {
        method: "GET",
        headers: Object.assign({}, getAcceptCSVHeaders(), getAuthHeader(token),)
    });
  
export const getRefundPayrollJPM = (token, {_from, _to}) =>
    fetch(accounting_urls.co.payroll.download.jpm_refund(_from, _to), {
        method: "GET",
        headers: Object.assign({}, getAcceptCSVHeaders(), getAuthHeader(token),)
    });

export const postOutflowsPayrollJPM = (token, formData) =>
    fetch(accounting_urls.co.payroll.upload.jpm(), {
        method: "POST",
        headers: getFormDataHeaders(token),
        body: formData
    });

export const postBankAccountsRegister = (token, formData) =>
  fetch(accounting_urls.co.uploads.bankAccounts.register(), {
    method: "PUT",
    headers: getFormDataHeaders(token),
    body: formData,
  }).then((r) => r.json());

export const postOutflowsPayroll = (token, formData, {_from, _to}) =>
  fetch(accounting_urls.co.uploads.movements.payroll(_from, _to), {
    method: "PUT",
    headers: getFormDataHeaders(token),
    body: formData,
  }).then((r) => r.json());

export const getInvoicingDetail = (token) =>
  fetch(accounting_urls.co.downloads.movements.invoicing(), {
    method: "GET",
    headers: getExcelBaseHeaders(token),
  }).then((r) => r.blob());

export const getInvoicingReportDetail = (token, dateFrom, dateTo) =>
  fetch(
    accounting_urls.co.downloads.movements.invoicing_report(dateFrom, dateTo),
    {
      method: "GET",
      headers: getSimpleZipBaseHeaders(token),
    }
  ).then((r) => r.blob());

//----------
// Mexico
//----------
export const getRegistrationFormMX = (token, { _to }) =>
  fetch(accounting_urls.mx.downloads.registrationForm(_to), {
    method: "GET",
    headers: getTxtBaseHeaders(token),
  }).then((r) => r.blob());

export const getPayrollMX =
  (internal) =>
    (token, { _from, _to }) =>
      fetch(accounting_urls.mx.downloads.payroll(_from, _to, internal), {
        method: "GET",
        headers: getTxtBaseHeaders(token),
      }).then((r) => r.blob());

export const getPayrollMXJPM =
    (token, {_from, _to}) =>
        fetch(accounting_urls.mx.downloads.payroll_jpm(_from, _to), {
            method: "GET",
            headers: Object.assign({}, getAcceptCSVHeaders(), getAuthHeader(token),)
        });

export const getRefundPayrollMXJPM =
    (token, {_from, _to}) =>
        fetch(accounting_urls.mx.downloads.payroll_jpm_refund(_from, _to), {
            method: "GET",
            headers: Object.assign({}, getAcceptCSVHeaders(), getAuthHeader(token),)
        });

export const postOutflowsPayrollMXJPM = (token, formData) =>
    fetch(accounting_urls.mx.uploads.payroll_jpm(), {
        method: "POST",
        headers: getFormDataHeaders(token),
        body: formData
    });

export const putRegistrationFormMX = (token, formData) =>
  fetch(accounting_urls.mx.uploads.registrationForm(), {
    method: "PUT",
    headers: getFormDataHeaders(token),
    body: formData,
  }).then((r) => r.json());

export const putPayrollMX = (internal) => (token, formData, {_from, _to}) =>
  fetch(accounting_urls.mx.uploads.payroll(internal, _from, _to), {
    method: "PUT",
    headers: getFormDataHeaders(token),
    body: formData,
  }).then((r) => r.json());

/**
  PAYMENTS API V2
*/

export const getBankAccounts = (country, token, id) =>
  fetch(accounting_urls[country].people.bank_accounts.all(id), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

export const getBankAccountRecord = (country, token, personId, identifier) =>
  fetch(
    accounting_urls[country].people.bank_accounts.record(personId, identifier),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.json());

export const getMovements = (
  country,
  token,
  type,
  concept,
  status,
  { _from, _to }
) =>
  fetch(
    accounting_urls[country].movements.get.all(
      type,
      concept,
      status,
      _from,
      _to
    ),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.json());

export const getServipagLogs = (token, { _from, _to }) =>
  fetch(accounting_urls.cl.servipag.logs.get.all(_from, _to), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

export const getMovementByServipagUID = (token, servipagUid) =>
  fetch(accounting_urls.common.movements.search("servipag_uid", servipagUid), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

export const getMovement = (country, token, id) =>
  fetch(accounting_urls[country].movements.get.single(id), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

export const getGroup = (country, token, id) =>
  fetch(accounting_urls[country].movements.get.group(id), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.json());

export const getMovementsBySubscription = (
  country,
  token,
  id,
  getOnly,
  { _from, _to }
) =>
  fetch(
    accounting_urls[country].subscriptions.movements.get(
      id,
      getOnly,
      _from,
      _to
    ),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.json());

export const uploadServipagLogs = (token, formData) =>
  fetch(accounting_urls.cl.servipag.uploadTxt(token), {
    method: "POST",
    headers: getFormDataHeaders(token),
    body: formData,
  }).then((r) => r.json());

export const getDuplicatedPayrollPayments = (
  token,
  from,
  to,
  type,
  get_duplicates,
  fileFormData
) =>
  fetch(
    accounting_urls.cl.payroll.checkDuplicated(from, to, type, get_duplicates),
    {
      method: "POST",
      headers: getFormDataHeaders(token),
      body: fileFormData,
    }
  ).then((r) => r.blob());

export const uploadPayrollPayments = (token, date, type, fileFormData) =>
  fetch(accounting_urls.cl.payroll.uploadDuplicatedPayroll(date, type), {
    method: "PUT",
    headers: getFormDataHeaders(token),
    body: fileFormData,
  }).then((r) => r.json());

// BACKEND

export const getAppraisers = () =>
  fetch(accounting_urls.appraisers.all(), {
    method: "GET",
    headers: getDefaultHeaders(),
  }).then((r) => r.json());

export const getExecutives = () =>
  fetch(accounting_urls.executives.all(), {
    method: "GET",
    headers: getDefaultHeaders(),
  }).then((r) => r.json());

// COMMISSIONS

export const getAppraiserCommission = (country, token, id, from, to) =>
  fetch(
    accounting_urls[country].commissions.appraisers.commission(id, from, to),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.json());

export const getAppraiserReport = (country, token, id, from, to, name) =>
  fetch(
    accounting_urls[country].commissions.appraisers.report(id, from, to, name),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.blob());

export const getAppraiserResume = (country, token, from, to, appraisers) =>
  fetch(accounting_urls[country].commissions.appraisers.resume(from, to), {
    method: "POST",
    headers: getBaseHeaders(token),
    body: JSON.stringify(appraisers),
  }).then((r) => r.blob());

export const getAppraisersBankReport = (country, token, from, to, appraisers) =>
  fetch(accounting_urls[country].commissions.appraisers.bankReport(from, to), {
    method: "POST",
    headers: getBaseHeaders(token),
    body: JSON.stringify(appraisers),
  }).then((r) => r.blob());

export const getAllAppraisersReports = (country, token, from, to, appraisers) =>
  fetch(accounting_urls[country].commissions.appraisers.allReports(from, to), {
    method: "POST",
    headers: getZipBaseHeaders(token),
    body: JSON.stringify(appraisers),
  }).then((r) => r.blob());

export const getExecutiveCommission = (country, token, id, from, to) =>
  fetch(
    accounting_urls[country].commissions.executives.commission(id, from, to),
    {
      method: "GET",
      headers: getBaseHeaders(token),
    }
  ).then((r) => r.json());

export const getExecutiveReport = (country, token, id, to, name) =>
  fetch(accounting_urls[country].commissions.executives.report(id, to, name), {
    method: "GET",
    headers: getBaseHeaders(token),
  }).then((r) => r.blob());

/**
 * Legacy Wrapper V1
 */
export const getMovementsCL = (token, concept, { _from, _to }) => {
  const reports = {
    reservation: getReservationsReport,
    contract: getContractsReport,
    lease: getLeasesReport,
  };
  return reports[concept](token, { _from, _to });
};
