export const columns = [
  'ID Solicitud/Propiedad',
  'Descripción',
  'Monto a pagar',
  'Fecha del pago',
  'Método de pago',
];

export const sizes = [2, 3, 2, 3, 2];

export const typeMapper = {
  'income': 'Recaudación',
  'outflow': 'Salida',
  'profit': 'Ingreso Houm',
};

export const conceptMapper = {
  'contract': 'Pago contrato',
  'lease': 'Pago mensualidad',
  'reservation': 'Pago reserva',
  'refund': 'Devolución',
};

const commonStatus = {
  'pending': 'No confirmado',
  'unpaid': 'Pendiente de pago',
  'paid': 'Pagado',
  'refunded': 'Reembolsado',
  'canceled': 'Anulado',
  'frozen': 'Congelado',
};

export const statusByCountry = {
  "cl": {
    ...commonStatus,
    "late": "Pago atrasado",
    "error": "Error en pago",
    "denied":  "Pago rechazado"
  },
  "co": {...commonStatus},
  "mx": {...commonStatus}
}

export const conceptsByCountry = {
  'cl': [
    { value: 'contract', label: 'Contrato' },
    { value: 'reservation', label: 'Reserva' },
    { value: 'lease', label: 'Mensualidad' },
  ],
  'co': [
    { value: 'contract', label: 'Contrato' },
    { value: 'reservation', label: 'Reserva' },
    { value: 'lease', label: 'Mensualidad' },
  ],
  'mx': [
    { value: 'contract', label: 'Contrato' },
    { value: 'reservation', label: 'Reserva' },
    { value: 'lease', label: 'Mensualidad' },
  ],
};
