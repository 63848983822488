export const env = {
  REACT_APP_PAYMENTS_COMMON_V1_URL: process.env.REACT_APP_PAYMENTS_COMMON_V1_URL,
  REACT_APP_PAYMENTS_CL_V2_URL: process.env.REACT_APP_PAYMENTS_CL_V2_URL,
  REACT_APP_PAYMENTS_CL_V3_URL: process.env.REACT_APP_PAYMENTS_CL_V3_URL,
  REACT_APP_PAYMENTS_CO_V3_URL: process.env.REACT_APP_PAYMENTS_CO_V3_URL,
  REACT_APP_PAYMENTS_MX_V2_URL: process.env.REACT_APP_PAYMENTS_MX_V2_URL,
  REACT_APP_PAYMENTS_MX_V3_URL: process.env.REACT_APP_PAYMENTS_MX_V3_URL,
  REACT_APP_PAYMENTS_COMMON_V3_URL: process.env.REACT_APP_PAYMENTS_COMMON_V3_URL,
  REACT_APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  REACT_APP_MS_COMMISSIONS_V1_URL: process.env.REACT_APP_MS_COMMISSIONS_V1_URL,
  REACT_APP_CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REACT_APP_DOMAIN: process.env.REACT_APP_DOMAIN,
  REACT_APP_AUDIENCE: process.env.REACT_APP_AUDIENCE,
  REACT_APP_AUTH0_ROLES: process.env.REACT_APP_AUTH0_ROLES,
  REACT_APP_AUTH0_LEGACY_TOKEN: process.env.REACT_APP_AUTH0_LEGACY_TOKEN,
}
