export const getDefaultHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
});

export const getAcceptExcelHeaders = () => ({
  Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'Content-Type': 'application/json',
});

export const getAcceptTxtHeaders = () => ({
  Accept: 'text/plain',
  'Content-Type': 'application/json',
});

export const getAcceptCSVHeaders = () => ({
  Accept: 'text/csv'
})

export const getAcceptZipHeaders = () => ({
  Accept: 'application/x-zip-compressed',
  'Content-Type': 'application/json',
});

export const getAcceptSimpleZipHeaders = () => ({
  Accept: 'application/zip',
  'Content-Type': 'application/json',
});

export const getUploadFormHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'multipart/form-data',
});

export const getAuthHeader = token => ({
  Authorization: `Bearer ${token}`,
});

export const getBaseHeaders = token => ({
  ...getDefaultHeaders(),
  ...getAuthHeader(token),
});

export const getExcelBaseHeaders = token => ({
  ...getAcceptExcelHeaders(),
  ...getAuthHeader(token),
});

export const getTxtBaseHeaders = token => ({
  ...getAcceptTxtHeaders(),
  ...getAuthHeader(token),
});

export const getZipBaseHeaders = token => ({
  ...getAcceptZipHeaders(),
  ...getAuthHeader(token),
});

export const getSimpleZipBaseHeaders = token => ({
  ...getAcceptSimpleZipHeaders(),
  ...getAuthHeader(token),
});

export const getFormDataHeaders = token => ({
  ...getAuthHeader(token),
});
